html,
body {
  margin: 0;
  padding: 0;
}

html {
  background-color: $color-white;
  color: $color-text-primary;
  font-family: $global-font-family;
  font-size: 100%;
  font-variant-ligatures: no-common-ligatures;
  line-height: $global-line-height-base;
}

body {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  min-height: 100vh;
}

blockquote {
  border-left: 4px solid $color-brand-secondary-blue;
  margin: $global-spacing-unit * 2 0 0 0;
  line-height: $global-line-height-base;
  padding: $global-spacing-unit;

  &:first-child {
    margin-top: 0;
  }

  cite {
    font-style: normal;
  }

  p {
    color: $color-brand-secondary-blue;
  }

  p:first-child:before {
    content: open-quote;
    display: block;
    font-size: 3.5rem;
    line-height: 0;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

p,
li,
dt,
dd {
  font-size: 1.25rem;
  line-height: $global-line-height-large;
}

dt,
dd {
  margin-top: 1rem;
}

dt {
  font-weight: $global-font-weight-bold;
}

dd {
  margin-left: 0;
}

p {
  margin: 0;
}

* + p {
  margin-top: $global-spacing-unit;
}

p:empty + p {
  margin-top: 0;
}

cite,
small {
  font-size: 0.875rem;
}

a {
  color: $color-link;

  &:visited {
    color: $color-link-visited;
  }

  &:active {
    color: $color-link-active;
  }

  &:hover {
    color: $color-link-hover;
  }
}

summary {
  cursor: pointer;
}

summary,
details a {
  color: $color-brand-primary-blue;
}

details p {
  padding: $global-spacing-unit / 1.5  $global-spacing-unit;
  margin-left: 0.3rem;
  border-left: 2px solid $color-medium-grey;
}

address {
  font-style: normal;
  margin-top: $global-spacing-unit;

  p {
    margin-top: 0;
  }
}

img {
  max-width: 100%;
}
