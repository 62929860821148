h1,
h2,
h3,
h4 {
  margin: $global-spacing-unit 0 0 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $global-font-weight-bold;
  line-height: $global-line-height-base;
}

h1 {
  font-size: 3rem;
  line-height: $global-line-height-small;
}

h1 small {
  display: block;
  margin-top: $global-spacing-unit;
}

h2 {
  font-size: 2rem;
  margin-top: $global-spacing-unit * 2;

  &:first-child {
    margin-top: 0;
  }
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}
