.o-list-bare,
.o-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}

.o-list-inline {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$global-spacing-unit;
}

.o-list-bare > li {
  margin-top: 1rem;
}

.o-list-inline > li {
  margin-left: $global-spacing-unit;
}

.o-dl-grid {
  display: grid;
  grid-column-gap: $global-spacing-unit;
  grid-row-gap: $global-spacing-unit / 4;
  grid-template-columns: auto 1fr;
}
