$global-breakpoint: 710px !default;
$global-breakpoint-gestures: 840px !default;

$global-font-family: 'DM Sans', Helvetica, Arial, sans-serif !default;
$global-font-size-base: 1rem !default;
$global-font-weight-medium: 500 !default;
$global-font-weight-bold: 700 !default;

$global-line-height-base: 1.5 !default;
$global-line-height-large: 1.8 !default;
$global-line-height-small: 1.3 !default;

$global-spacing-unit: 1.5rem !default;
$global-vertical-spacing: 4.125rem !default;

$global-border-radius: 6px !default;
