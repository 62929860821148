$color-brand-pale-mid-blue: rgba(209, 236, 246, 1);

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.c-deep-breathing-tool {
  margin-bottom: $global-spacing-unit;
  padding-bottom: $global-spacing-unit * 4;

  &__frame {
    background-color: #fff;
    margin-bottom: $global-spacing-unit;
    position: relative;

    @media (min-width: 448px) {
      height: 400px;
      width: 400px;
    }
  }

  &__outer-circle,
  &__inner-circle {
    border: 1px solid #dfdfdf;
    border-radius: 50%;
    margin-bottom: $global-spacing-unit;
  }

  &__outer-circle {
    background-color: $color-brand-pale-mid-blue;
    height: calc(100vw - 3rem);
    width: calc(100vw - 3rem);

    @media (min-width: 448px) {
      height: 100%;
      width: 100%;
    }
  }

  &__inner-circle {
    background-color: #efefef;
    -webkit-box-shadow: 0 0 15px 5px #ddd;
    box-shadow: 0 0 15px 5px #ddd;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    transform: scale(0.4);
    transition: all 0.05s linear;
    width: 100%;
  }

  &__inner-circle--breathe-in {
    background-color: #fff;
    transform: scale(0.9);
    transition: all 4s linear;
  }

  &__inner-circle--breathe-out {
    background-color: #efefef;
    transform: scale(0.4);
    transition: all 4s linear;
  }

  &__message {
    font-family: 'DM Sans', Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    left: 1rem;
    position: absolute;
    right: 1rem;
    text-align: center;
    text-shadow: 0 0 0.2em rgba(255, 255, 255, 0.8),
      0 0 0.2em rgba(255, 255, 255, 0.8), 0 0 0.2em rgba(255, 255, 255, 0.8);
    top: 50%;
    transform: translateY(-50%);
  }

  &__message--fade {
    animation: fade-in-out 4s infinite;
  }

  &__animation-toggle {
    display: block;
    max-width: 400px;
    position: relative;
  }
}
