$icon-size: 24px;

.o-icon {
  max-width: $icon-size;
}

.o-image {
  max-width: 100vw;
  margin-left: -$global-spacing-unit;
}

@media (min-width: $global-breakpoint) {
  .o-image {
    max-width: 100%;
    margin: 0;
  }
}
