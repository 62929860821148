.c-centered-callout {
  text-align: center;

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }

  * + * {
    margin-top: $global-spacing-unit;
  }
}
