$masthead-image-width: 436px;

.c-masthead {
  color: $color-text-primary;
  padding: 3rem 0;

  .c-masthead__title {
    color: $color-text-primary;
    margin-top: 0;
  }

  .c-masthead__image {
    margin-top: $global-vertical-spacing;
  }

  @media (max-width: $global-breakpoint) {
    .c-masthead__title {
      font-size: 2.25rem;
    }

    .c-masthead__lede {
      font-size: 1.5rem;
    }
  }

  @media (max-width: $global-breakpoint-gestures) {
    .c-masthead__image,
    .o-gesture__image {
      display: none;
    }
  }

  @media (min-width: $global-breakpoint-gestures) {
    .c-masthead__wrapper {
      display: flex;
      flex-wrap: wrap;

      > *:first-child {
        max-width: 75%;
      }
    }

    .c-masthead__image {
      margin-right: -50%;
      margin-top: 0;
      max-width: $masthead-image-width;
    }
  }
}

.c-masthead--dark,
.c-masthead--teal {
  .c-masthead__title {
    color: $color-white;
  }
}

.c-masthead--light,
.c-masthead--yellow {
  .c-masthead__title {
    color: $color-brand-primary-blue;
  }
}

.c-masthead--dark {
  background-color: $color-brand-primary-blue;
  color: $color-white;
}

.c-masthead--light {
  background-color: $color-pale-blue;
}

.c-masthead--teal {
  background-color: $color-brand-teal;
}

.c-masthead--white {
  background-color: $color-white;
}

.c-masthead--yellow {
  background-color: $color-brand-yellow;
}

@media (min-width: $global-breakpoint) {
  .c-masthead {
    padding-top: 3rem;
  }
}
