.c-image-grid {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-left: -$global-spacing-unit;
  padding: 0;

  > li {
    flex: 0 1 calc(50% - #{$global-spacing-unit});
    margin-left: $global-spacing-unit;
  }
}

@media (min-width: $global-breakpoint) {
  .c-image-grid > li {
    flex: 0 1 calc(1/3 * 100 * 1% - #{$global-spacing-unit});
  }
}
