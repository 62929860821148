.c-service-listing {
  a {
    color: $color-text-primary;
    text-decoration: none;
  }

  .c-service-listing__brand {
    color: $color-link;
    margin-top: 0;
    margin-bottom: 0;
  }

  .c-service-listing__title {
    color: $color-link;
    margin-top: 0.5rem;
  }
}
