$color-pale-yellow: hsl(35, 95%, 92%);

.c-banner {
  color: $color-text-primary;
  padding: $global-spacing-unit 0;
  position: relative;

  p {
    font-size: 1.125rem;
    font-weight: bold;
  }

  p:first-child {
    margin-top: 0;
  }

  &--pale-blue {
    background-color: $color-pale-blue;
  }

  &--teal {
    background-color: $color-brand-teal;
  }

  &--notice,
  &--yellow {
    background-color: $color-brand-yellow;
  }

  &--pale-yellow {
    background-color: $color-pale-yellow;
  }

  &--white {
    background-color: $color-white;
  }

  &--slim {
    padding: 1rem 0;

    p {
      font-size: 0.875rem;
      font-weight: normal;
    }
  }

  &__dismiss {
    float: right;
    margin-right: $global-spacing-unit;
  }

  &__button {
    margin-top: $global-spacing-unit;
    width: 100%;
  }

  @media (min-width: $global-breakpoint) {
    &__dismiss {
      float: none;
      position: absolute;
      top: $global-spacing-unit;
      right: 0;
    }

    &__button {
      margin-top: 0;
      width: auto;
    }

    &--slim .c-banner__button {
      margin-left: $global-spacing-unit;
    }

    .c-banner__button {
      margin-left: $global-spacing-unit;
    }
  }
}
