.c-statistic {
  font-weight: $global-font-weight-bold;
  margin-top: $global-spacing-unit;

  &:first-child {
    margin-top: 0;
  }

  > p {
    margin: 0;
  }

  .c-statistic__stat {
    font-size: 5.25rem;
    line-height: 1.4;
  }

  .c-statistic__above,
  .c-statistic__below {
    font-size: 2rem;
  }
}

@media (max-width: $global-breakpoint) {
  .c-statistic .c-statistic__stat {
    font-size: 4rem;
  }
}
