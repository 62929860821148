ul.c-autocomplete-results {
  border: 2px solid $color-medium-grey;
  border-radius: 0.5rem;
  margin-top: -2px;

  li {
    border-bottom: 1px solid $color-medium-grey;
    margin-top: 0;
    padding: 0.75rem;
  }

  li:focus {
    background-color: $color-bg-notice;
    outline: none;
  }

  li:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  li:last-child {
    border-bottom: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.c-autocomplete-results:empty {
  border: none;
}
