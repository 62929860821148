$global-breakpoint: 710px;

.c-modal-overlay {
  background-color: rgba(1, 1, 1, 0.75);
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.c-modal-overlay--hidden {
  display: none;
}

.c-modal-panel {
  background-color: #fff;
  border-radius: 6px;
  height: auto;
  left: 50%;
  max-width: 500px;
  padding: 2rem;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.c-modal-panel a {
  margin-right: 1rem;
}

.c-modal-panel fieldset {
  margin-top: 0;
}

@media (min-width: $global-breakpoint) {
  .c-modal-panel {
    width: 50%;
  }
}

.c-modal-controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
