$icon-size: 32px;

.c-accordion {
  background-color: $color-light-grey;
  border-radius: 6px;
  border: 2px solid $color-medium-grey;
  color: $color-text-primary;
  margin: 1rem 0;
  padding: 0 1rem;
  position: relative;
  transition: background-color 0.15s;

  &[open] {
    padding-bottom: $global-spacing-unit;

    summary + * > *:first-child {
      margin-top: 0;
    }

    summary {
      padding-bottom: 0;
    }

    summary:after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIzLjg5MyAyMi41aDguNjc4YzEuMDY1IDAgMS45MjktLjg5NiAxLjkyOS0ydi0yYzAtMS4xMDQtLjg2NC0yLTEuOTI5LTJIOS40M2MtMS4wNjUgMC0xLjkyOS44OTYtMS45MjkgMnYyYzAgMS4xMDQuODY0IDIgMS45MjkgMmgxNC40NjR6IiBmaWxsPSIjMDcwOTFEIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
      background-size: $icon-size $icon-size;
      content: "";
      display:inline-block;
      height: $icon-size;
      margin-right: -45px;
      width: $icon-size;
    }
  }

  summary {
    color: $color-link;
    cursor: pointer;
    display: block;
    font-size: 1.33em;
    font-weight: bold;
    padding: 1rem 2em 1rem 0;
    position: relative;

    &::after {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguOTI5IDIyLjM5M2g4LjY3OHY4LjY3OGMwIDEuMDY1Ljg2NCAxLjkyOSAxLjkyOSAxLjkyOWgxLjkyOGExLjkyOSAxLjkyOSAwIDAwMS45MjktMS45Mjl2LTguNjc4aDguNjc4QTEuOTI5IDEuOTI5IDAgMDAzNCAyMC40NjR2LTEuOTI4YTEuOTI5IDEuOTI5IDAgMDAtMS45MjktMS45MjloLTguNjc4VjcuOTNBMS45MjkgMS45MjkgMCAwMDIxLjQ2NCA2aC0xLjkyOGExLjkyOSAxLjkyOSAwIDAwLTEuOTI5IDEuOTI5djguNjc4SDguOTNBMS45MjkgMS45MjkgMCAwMDcgMTguNTM2djEuOTI4YzAgMS4wNjUuODY0IDEuOTI5IDEuOTI5IDEuOTI5eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
      background-size: $icon-size $icon-size;
      content: "";
      height: $icon-size;
      margin-right: -45px;
      position: absolute;
      right: 36px;
      top: 1.25rem;
      width: $icon-size;
    }

    &:focus {
      outline: none;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &:focus-within {
    box-shadow: 0 0 0 3px $color-brand-yellow;
  }

  p {
    border-left: 0;
    line-height: 1.5;
    margin-left: 0;
    padding: 0;
  }
}
