$wrapper-max-width: 1024px;
$strip-padding: $global-spacing-unit * 2;

.o-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $wrapper-max-width;
  padding-left: $global-spacing-unit;
  padding-right: $global-spacing-unit;
  width: 100%;
}

.o-wrapper--full {
  max-width: none;
}

.o-strip {
  overflow-x: hidden;
  padding-bottom: $strip-padding;
  padding-top: $strip-padding;
}

.o-strip--light {
  background-color: $color-pale-blue;
}

.o-strip--dark {
  background-color: $color-brand-primary-blue;
  color: $color-white;

  blockquote {
    border-color: $color-white;

    p {
      color: $color-white;
    }
  }

  a {
    color: inherit;
  }
}

.o-strip--teal {
  background-color: $color-brand-teal;
}

.o-strip--white {
  background-color: $color-white;
}

.o-strip--yellow {
  background-color: $color-brand-yellow;
}

.o-strip--grey {
  background-color: $color-light-grey;
}

.o-grid-golden {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
}

@media (min-width: $global-breakpoint) {
  .o-grid-golden {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 2fr 1fr;
  }
}

@media (min-width: $global-breakpoint) {
  .o-flex {
    display: flex;
  }

  .o-flex > * {
    flex: 1 1;
    margin-right: 2rem;
  }

  .o-flex > *:last-child {
    margin-right: 0;
  }

  .o-flex--1\/2 {
    flex-wrap: wrap;
  }

  .o-flex--1\/2 > * {
    flex: 1 1 calc(50% - 2rem);
  }

  .o-flex--1\/2 > *:nth-child(even) {
    margin-right: 0;
  }

  .o-flex--2\/3 > *:nth-child(odd) {
    flex: 2;
  }

  .o-flex--2\/3-nav > *:nth-child(even) {
    flex: 2;
  }
}
