$logo-height: 48px;
$logo-width: 148px;
$vertical-padding: $global-spacing-unit / 2;

.c-page-header {
  background-color: $color-brand-primary-blue;
  color: $color-white;
  padding-bottom: $global-spacing-unit;
  padding-top: $global-spacing-unit;

  .o-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .c-page-header__logo {
    display: inline-block;
  }

  .c-page-header__logo img {
    width: $logo-width;
  }

  .c-page-header__nav {
    font-weight: $global-font-weight-bold;
    padding: $vertical-padding / 2 0;

    ul {
      align-items: baseline;
    }

    li {
      font-size: 1.125rem;
      margin-top: 0;
      width: auto;
    }

    a {
      text-decoration: none;

      &:visited, &:active, &:hover {
        color: $color-link;
      }

      &:hover {
        border-bottom: 2px solid $color-white;
      }
    }

    .c-page-header__nav-donate {
      border-bottom: 0;

      .c-button {
        color: $color-brand-primary-blue;
        margin-top: 0;
        padding: 0.625rem 1rem;
        width: auto;
      }

      .c-button:after {
        content: unset;
      }

      .c-button:hover {
        border-color: darken($color-button-yellow-bg-hover, 15%);
      }
    }
  }

  .c-page-header__nav-toggle {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  [aria-expanded="true"] {
    position: absolute;
    right: 24px;
  }
}

.c-page-header--light {
  background-color: $color-white;
  color: $color-brand-primary-blue;

  .c-page-header__nav a:hover {
    border-bottom: 2px solid $color-brand-primary-blue;
  }
}

@media (max-width: $global-breakpoint) {
  .c-page-header .c-page-header__nav {
    width: 100%;

    ul {
      display: block;
      margin-top: $global-spacing-unit;
    }

    li {
      border-bottom: 1px solid $color-brand-primary-blue;
      line-height: 2.5em;
      width: 90vw;

      a:after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjQyNyA4LjgwOEwxOS44IDcuNDM2YTEuNDc4IDEuNDc4IDAgMDEyLjA5NiAwbDEyLjAxOSAxMi4wMTNhMS40NzggMS40NzggMCAwMTAgMi4wOTZsLTEyLjAyIDEyLjAyYTEuNDc4IDEuNDc4IDAgMDEtMi4wOTUgMGwtMS4zNzMtMS4zNzNhMS40ODYgMS40ODYgMCAwMS4wMjUtMi4xMjFsNy40NS03LjA5OEg4LjEzM2ExLjQ4IDEuNDggMCAwMS0xLjQ4NC0xLjQ4NHYtMS45NzhhMS40OCAxLjQ4IDAgMDExLjQ4NC0xLjQ4NGgxNy43N2wtNy40NTEtNy4wOThhMS40NzUgMS40NzUgMCAwMS0uMDI1LTIuMTJ6IiBmaWxsPSIjMkUzQ0ZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
        position: absolute;
        right: 60px;
        width: 18px;
      }
    }

    .c-page-header__nav-donate .c-button {
      margin-top: 1rem;
      padding-top: 0;
      padding-bottom: 0;
      width: 100%;
    }
  }
}
