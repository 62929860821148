.o-gesture {
  .o-wrapper {
    .o-gesture__image {
      margin-top: $global-vertical-spacing;

      svg {
        display: block;
        max-height: 100%;
        max-width: 100%;
        transform: scaleX(-1);
        width: 999px;
      }
    }
  }

  @media (min-width: $global-breakpoint) {
    .o-wrapper {
      > *:first-child {
        max-width: 75%;
      }
    }
  }

  @media (min-width: $global-breakpoint-gestures) {
    .o-wrapper {
      display: flex;
      flex-wrap: wrap;

      .o-gesture__image {
        max-width: 580px;
        margin: 0 -50% 0 $global-spacing-unit;
      }
    }
  }

  &--primary-blue path {
    fill: $color-brand-primary-blue;
  }

  &--secondary-blue path {
    fill: $color-brand-secondary-blue;
  }

  &--coral path {
    fill: $color-brand-coral;
  }

  &--yellow path {
    fill: $color-brand-yellow;
  }

  &--teal path {
    fill: $color-brand-teal;
  }
}
