$icon-width: 24px;
$shadow-depth: 6px;

.c-button {
  background-color: $color-button-blue-bg;
  border: 2px solid $color-brand-primary-blue;
  border-radius: 0.5rem;
  box-shadow: 0 $shadow-depth $color-brand-primary-blue;
  color: $color-text-primary;
  cursor: pointer;
  display: inline-block;
  font-family: $global-font-family;
  font-size: 1.125rem;
  font-weight: $global-font-weight-bold;
  margin-bottom: $shadow-depth;
  padding: 0.75rem 2rem;
  text-align: center;
  text-decoration: none;

  &:hover,
  &:active,
  &:visited {
    color: $color-text-primary;
  }

  &:hover {
    background-color: $color-button-blue-bg-hover;
  }

  &:disabled {
    box-shadow: 0 3px transparent;
    opacity: 0.5;
  }
}

.c-button--yellow {
  background-color: $color-button-yellow-bg;
  border-color: darken($color-button-yellow-bg-hover, 15%);
  box-shadow: 0 6px darken($color-button-yellow-bg-hover, 15%);

  &:hover {
    background-color: $color-button-yellow-bg-hover;
  }
}

.c-button--green {
  background-color: $color-success;
  border-color: darken($color-success, 10%);
  box-shadow: 0 3px darken($color-success, 10%);
  color: $color-white;

  &:hover {
    background-color: $color-button-green-bg-hover;
    color: $color-white;
  }

  &:disabled {
    box-shadow: 0 3px transparent;
  }
}

.c-button--simple {
  font-size: 0.875rem;
  font-weight: normal;
  background-color: $color-white;
  border: 2px solid $color-brand-primary-blue;
  border-radius: 5px;
  box-shadow: none;
  color: $color-brand-primary-blue;
  padding: 0.5em;

  &:disabled {
    box-shadow: none;
  }

  &:hover {
    background-color: $color-white;
    color: $color-brand-primary-blue;
    text-decoration: underline;
  }
}

.c-icon-button {
  cursor: pointer;
  width: $icon-width;
}
