form {
  margin: 0;
}

fieldset {
  border: none;
  margin-top: 2rem;
  padding: 0;
}

legend,
label {
  display: block;
  font-size: 1.125rem;
  font-weight: $global-font-weight-bold;
}

legend {
  width: 100%;
}

input,
textarea,
select,
button {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px $color-brand-yellow;
  }
}

input,
textarea {
  padding: 0.75rem;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
select,
textarea {
  background-color: $color-white;
  border: 2px solid $color-medium-grey;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  margin-top: 1rem;
  max-width: 100%;
  width: 100%;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI2LjI2MiAxLjYyM2MtMS4wMTUtMS4wNzgtMi40MjgtMS4xNjItMy42NjggMGwtOS4wOTcgOS4wNTYtOS4wOTYtOS4wNTZDMy4xNi40NjEgMS43NDUuNTQ1LjczNyAxLjYyM2MtMS4wMTUgMS4wNzYtLjk1IDIuODkzIDAgMy45MDQuOTQ1IDEuMDEgMTAuOTI4IDEwLjg4IDEwLjkyOCAxMC44OC41MDUuNTQgMS4xNjkuODEgMS44MzIuODEuNjY0IDAgMS4zMjctLjI3IDEuODM3LS44MSAwIDAgOS45NzktOS44NyAxMC45MjgtMTAuODguOTUyLTEuMDEgMS4wMTUtMi44MjggMC0zLjkwNHoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==");
  background-position: right 0.7em top 50%;
  background-repeat: no-repeat;
  padding: 0.75rem 2.2rem 0.75rem 0.75rem;
}
