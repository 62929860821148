$color-brand-coral: #ff5b6a;
$color-brand-coral--tint: #ffdee1;
$color-brand-teal: #00a791;
$color-brand-teal--tint: #ccede9;

h1 {
  margin-top: 0;
}

a,
a:hover {
  text-decoration: none;
}

a:hover {
  cursor: pointer;
}

.link-go-back {
  text-decoration: none;
}

.link-go-back::before {
  content: '\003C ';
}

/* item list styling */

.lists-page-subhead {
  border-top: 1px solid #999;
  margin-top: 3rem;
  padding-top: 3rem;
}

.final-list {
  margin-top: 1.5rem;
}

.good-things-list-content,
.bad-things-list-content {
  display: block;
  word-break: normal;
}

.good-things-list-content .list-row,
.bad-things-list-content .list-row {
  display: flex;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  padding: 1.5rem;
}

.final-good-things-list-content .list-row,
.final-bad-things-list-content .list-row {
  display: block;
  margin: 1.5rem;
}

.good-things-list-content .list-row,
.bad-things-list-content .list-row,
.final-good-things-list-content,
.final-bad-things-list-content {
  border: 2px solid #fff;
  border-radius: 12px;
  color: #07091d;
  font-weight: bold;
}

.good-things-list-content .list-row,
.final-good-things-list-content {
  background-color: $color-brand-teal--tint;
  border-color: $color-brand-teal;
}

.bad-things-list-content .list-row,
.final-bad-things-list-content {
  background-color: $color-brand-coral--tint;
  border-color: $color-brand-coral;
}

.list-row-item {
  width: 100%;
}

.delete-button:hover {
  cursor: pointer;
}

.add-suggestion {
  line-height: 4rem;
  margin-left: 10px;
}

/* summary page */

.o-strip--summary {
  padding-top: 1.5rem;
}

.summary-content {
  margin-top: 1.5rem;
}

.counts {
  text-align: center;
}

.count-good {
  background-color: $color-brand-teal--tint;
}

.count-bad {
  background-color: $color-brand-coral--tint;
}

.summary-count {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}

.summary-count-sub {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}
