$vertical-spacing: $global-vertical-spacing / 3;

.c-page-footer {
  background-color: $color-light-grey;
  color: $color-medium-grey;
  padding-bottom: $vertical-spacing;
  padding-top: $vertical-spacing;

  .c-page-footer__top-menus {
    li {
      margin-top: 0.3125rem;
    }

    a {
      color: $color-text-primary;
      font-size: 0.875rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .c-page-footer__logo-social {
      & > * {
        margin-top: $global-spacing-unit;
      }

      ul img {
        max-width: 24px;
      }
    }

    .c-page-footer__logo {
      img {
        width: 148px;
      }
    }

    .c-page-footer__logo + * {
      margin-top: $global-spacing-unit;
    }

    @media (min-width: $global-breakpoint) {
      display: block;

      .c-page-footer__logo-social {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }
  }

  .c-page-footer__details {
    border-top: 1px solid $color-medium-grey;
    font-size: 0.75rem;
    margin-top: $global-spacing-unit;
    padding-top: $global-spacing-unit;

    > :first-child {
      margin-bottom: $global-spacing-unit;
    }

    .o-list-inline {
      align-items: flex-end;
    }

    @media (min-width: $global-breakpoint) {
      align-items: flex-start;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      li {
        margin-top: 0;
      }
    }
  }

  .c-page-footer__details p {
    font-size: 0.75rem;
  }

  .c-page-footer__fundraising {
    img {
      max-width: 116px;
    }
  }
}
