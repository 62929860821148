@mixin box-content {
  color: $color-text-primary;
  margin-top: $global-spacing-unit;
  word-break: break-word;

  &__content {
    padding: $global-spacing-unit;

    > * + * {
      margin-top: $global-spacing-unit;
    }
  }
}
