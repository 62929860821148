$border-size: 0.75rem;
$card-border: 3px solid $color-brand-primary-blue;
$icon-width: 70px;

.c-card {
  @include box-content;
  background-color: $color-white;

  &__title:first-child {
    margin-top: 0;
  }

  &__title a {
    color: $color-brand-primary-blue;
    text-decoration: none;
  }

  &__button {
    margin-top: $global-spacing-unit;
    width: 100%;
  }
}

.c-card--nav {
  border: $card-border;
  border-radius: 0;

  display: flex;
  flex-direction: column;

  .c-card__content {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .rich-text {
      margin-bottom: $global-spacing-unit;
    }

    .rich-text:last-child {
      margin-bottom: auto;
    }

    .c-card__button {
      margin-top: auto;
    }

    ul {
      list-style-type: none;
      padding: 0;
    }

    li {
      margin-top: $global-spacing-unit;
    }

    a {
      font-weight: $global-font-weight-bold;
    }
  }

  .c-card__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: $card-border;
    min-height: 15rem;
    width: 100%;
  }
}

.c-card--shadowed {
  box-shadow: 0 0 4px hsla(0, 0%, 0%, 0.5);
}

.c-card--person + .c-card--person {
  margin-top: $global-spacing-unit * 3;
}

.c-card--centered {
  text-align: center;
}

.c-card--bordered {
  border-radius: $global-border-radius;
  border: 2px solid $color-faded-blue;
}

.c-card--bordered__icon {
  margin-bottom: 0.625rem;
}

.c-card--sidenav {
  border-top: 0.75rem solid $color-brand-secondary-blue;
}

@media (min-width: $global-breakpoint) {
  .c-card--bordered {
    align-items: flex-start;
    display: flex;

    > * {
      flex: 1;
    }

    .c-card--bordered__icon {
      flex: 0 1 $icon-width;
      margin-bottom: 0;

      > img {
        width: 100%;
      }
    }
  }

  .c-card--person {
    .c-card__content {
      padding-left: 0;
      padding-right: 0;
    }

    .c-card__image {
      img {
        border-radius: $global-border-radius;
      }
    }
  }

  .c-card__button {
    width: auto;
  }

  .c-card--nav:only-child {
    display: flex;
    flex-direction: row-reverse;

    .c-card__image {
      border-bottom: 0;
      border-left: $card-border;
      flex-basis: 33%;
    }
    .c-card__content {
      flex: 1;
    }
  }
}
