.c-breadcrumbs {
  padding-bottom: 0;
  padding-top: 1rem;

  li {
    font-size: 0.9375rem;
    position: relative;
  }

  li + li,
  .c-breadcrumbs__single-item {
    padding-left: 1rem;
  }

  li + li {
    margin-left: 0.5rem;
  }

  li + li::before,
  .c-breadcrumbs__single-item::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjEyMyA3LjIzOGMtMS4wNzggMS4wMTUtMS4xNjIgMi40MjggMCAzLjY2OGw5LjA1NiA5LjA5Ny05LjA1NiA5LjA5NmMtMS4xNjIgMS4yNDEtMS4wNzggMi42NTYgMCAzLjY2NCAxLjA3NiAxLjAxNSAyLjg5My45NSAzLjkwNCAwIDEuMDEtLjk0NSAxMC44OC0xMC45MjggMTAuODgtMTAuOTI4LjU0LS41MDUuODEtMS4xNjkuODEtMS44MzIgMC0uNjY0LS4yNy0xLjMyNy0uODEtMS44MzcgMCAwLTkuODctOS45NzktMTAuODgtMTAuOTI4LTEuMDEtLjk1Mi0yLjgyOC0xLjAxNS0zLjkwNCAweiIgZmlsbD0iIzJFM0NGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    bottom: 1px;
    left: 0;
    width: 12px;
    height: 12px;
    margin: auto 0;
    opacity: 0.6;
  }

  .c-breadcrumbs__single-item::before {
    transform: scaleX(-1);
  }
}

@media (min-width: $global-breakpoint) {
  .c-breadcrumbs {
    padding-top: 2rem;
  }
}
