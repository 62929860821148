.c-inset-text {
  border-left: 4px solid $color-medium-grey;
  margin: $global-spacing-unit * 2 0 0 0;
  line-height: $global-line-height-base;
  padding: $global-spacing-unit;

  &:first-child {
    margin-top: 0;
  }
}
