.c-drinkscheckin-tool {
  .o-strip--yellow {
    background-color: #feeed7;
  }

  .c-card__bordered--light {
    border: 2px solid #eeedec;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }

  .o-form-field--radio:first-child {
    margin-top: 0;
  }

  &__radio-detail {
    margin-top: 2rem;
    padding-top: 0;
  }

  &__radio-detail .c-card {
    background: #d1ecf6;
    border-radius: 1rem;
  }

  legend {
    font-size: 1.75rem;
  }

  &__form-sublegend {
    color: #6f7180;
    font-size: 1.35rem;
    font-weight: 400;
  }

  &__start-button {
    margin-top: 1rem;
  }

  &__set-goal-card {
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  &__auditc-unit-info img {
    margin: 0;
  }

  [data-section-auditc] .o-strip,
  [data-section-other-questions] .o-strip {
    padding-top: 1.5rem;
  }

  [data-section-results] .o-strip {
    padding-top: 2rem;
  }

  [data-section-results] {
    margin-top: 1.5rem;
  }

  [data-results-header] {
    display: flex;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
    margin: 1.5rem 0 0;
    position: relative;
  }

  [data-results-summary] {
    margin-top: 1.5rem;
  }

  [data-suggested-changes] .c-card {
    background: #d1ecf6;
    border-radius: 1rem;
    font-size: 1.25rem;
    line-height: 1.8;
    margin-right: 20px;
    text-align: center;
  }

  [data-suggested-changes] .c-card__title {
    margin-top: 0;
  }

  [data-suggested-changes] img {
    max-width: 4rem;
  }

  [data-suggested-changes] .suggestion-label,
  .suggestion-detail {
    margin: 0;
  }

  [data-suggested-changes] .suggestion-label {
    font-weight: bold;
  }

  [data-suggested-changes] .suggestion-detail {
    margin-bottom: 1.5rem;
    min-height: 5.4em;
  }

  [data-more-advice] {
    padding-top: 30px;
  }

  .advice-list {
    margin-top: 1.5rem;
  }
  .advice-content {
    display: block;
  }
  .advice-content-row {
    display: flex;
    padding: 0.5rem 0;
  }
  .advice-content-icon {
    padding: 5px 10px 10px;
  }

  .advice-content-icon img {
    max-width: 2rem;
  }
  .advice-content-text {
    font-size: 1.25rem;
    line-height: 1.8;
  }
}

@media print {
  .hide-on-print {
    display: none;
  }
}
