.u-inline {
  display: inline-block;
}

.u-mt-auto {
  margin-top: auto;
}
.u-mt-0 {
  margin-top: 0;
}

.u-mt-1 {
  margin-top: $global-spacing-unit;
}

.u-mt-3 {
  margin-top: $global-spacing-unit * 2;
}

.u-center {
  margin-left: auto;
  margin-right: auto;
}
