.c-smartgoals-tool {
  textarea {
    font-family: Arial;
  }

  &__goal-frame {
    background-color: $color-brand-primary-blue;
    border: 1px solid #000;
    box-sizing: content-box;
    margin-bottom: 40px;
    margin-top: 30px;
    padding: 10%;
    width: 300px;

    @media (min-width: 448px) {
      width: 600px;
    }
  }

  &__goal-content {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    min-height: 300px;
    position: relative;
    width: 300px;
    @media (min-width: 448px) {
      font-size: 32px;
      min-height: 600px;
      width: 600px;
    }
  }

  &__big-goal-wrapper {
    font-size: 1.25rem;
    font-weight: normal;
    padding-bottom: 10px;
    @media (min-width: 448px) {
      font-size: 32px;
      padding-bottom: 20px;
    }
  }

  &__big-goal {
    font-weight: bold;
  }

  &__first-step-wrapper {
    padding-bottom: 3.5rem;
    padding-top: 10px;
  }

  &__first-step-wrapper div {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.3;

    @media (min-width: 448px) {
      font-size: 3rem;
    }
  }

  &__first-step-wrapper img {
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%;
  }

  &__goal-footer {
    bottom: 0;
    display: flex;
    font-size: 16px;
    position: absolute;
    width: 100%;
    @media (min-width: 448px) {
      font-size: 32px;
    }
  }

  &__goal-footer-logo {
    text-align: left;
    width: 100%;
  }

  &__goal-footer-logo img {
    max-width: 60px;

    @media (min-width: 448px) {
      max-width: 120px;
    }
  }
}
