table {
  border-collapse: collapse;
  font-size: 1rem;
  margin-top: $global-spacing-unit * 2;
  width: 100%;
}

caption,
th,
td {
  padding: 0.75rem 0.375rem;
}

th,
td {
  border-bottom: 1px solid $color-medium-grey;
}

caption,
th {
  font-weight: $global-font-weight-bold;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: $color-pale-blue;
}

tbody tr:hover {
  background-color: $color-bg-notice;
}
