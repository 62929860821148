.c-distraction-tool {
  &__frame {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__random-activity {
    color: #000;
    margin-top: 0;
    opacity: 1;
    transition: opacity 500ms;
  }

  &__random-activity--inactive {
    color: #aaa;
  }

  &__random-activity--faded-out {
    opacity: 0;
  }

  &__activity-dropdown {
    background-color: #fff;
  }

  &__activity-list {
    padding-top: 20px;
  }
}
