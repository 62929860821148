.c-webchat-cta {
  align-items: center;
  background-color: $color-white;
  border: {
    top: 2px solid $color-brand-primary-blue;
    right: 2px solid $color-brand-primary-blue;
    left: 2px solid $color-brand-primary-blue;
  }
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12px;
  color: $color-text-primary;
  display: flex;
  font-weight: $global-font-weight-bold;
  padding: 0.8rem 1rem;
  position: fixed;
  right: $global-spacing-unit;
  text-decoration: none;
  z-index: 100;

  > img {
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: $color-pale-blue;
  }
}
