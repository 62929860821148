.o-lede {
  color: $color-brand-primary-blue;
  font-size: 1.5625rem;
}

.o-back-link {
  display: block;
  text-decoration: none;

  &:before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTQuMTkgMTYuMDA1bDcuODY5IDcuODY4LTIuMTI5IDIuMTI5LTkuOTk2LTkuOTk3TDE5LjkzNyA2LjAwMmwyLjEyNyAyLjEyOXoiLz48L3N2Zz4=');
    background-size: $global-spacing-unit;
    display: inline-block;
    width:  $global-spacing-unit;
    height:  $global-spacing-unit / 1.25;
    content: '';
    padding-right: $global-spacing-unit / 2;
  }
}
