// Brand colours
$color-brand-primary-blue:   hsl(236, 100%, 59%) !default;
$color-brand-secondary-blue: hsl(196, 77%, 46%) !default;
$color-brand-coral:          hsl(355, 100%, 68%) !default;
$color-brand-yellow:         hsl(36, 95%, 61%) !default;
$color-brand-teal:           hsl(171, 100%, 33%) !default;

// Blues
$color-faded-blue: hsl(236, 100%, 75%) !default;
$color-pale-blue: hsl(234, 100%, 96%) !default;

// Greys
$color-dark-grey: hsl(234, 60%, 7%) !default;
$color-light-grey: hsl(30, 6%, 93%) !default;
$color-medium-grey: hsl(233, 7%, 47%) !default;

// Solid colours
$color-black: hsl(0, 0%, 0%) !default;
$color-white: hsl(109, 100%, 100%) !default;

// Alert and form states
$color-success: hsl(132, 77%, 28%) !default;
$color-notice: $color-brand-yellow !default;
$color-danger: hsl(354, 83%, 27%) !default;
$color-bg-danger: hsl(354, 99%, 84%) !default;
$color-bg-notice: hsl(35, 97%, 92%) !default;
$color-bg-success: hsl(173, 48%, 86%) !default;

$color-text-primary: $color-dark-grey !default;
$color-link: $color-brand-primary-blue !default;
$color-link-active: $color-black !default;
$color-link-hover: hsl(236, 100%, 34%) !default;
$color-link-visited: hsl(262, 60%, 44%) !default;

$color-button-blue-bg: hsl(236, 100%, 94%) !default;
$color-button-blue-bg-hover: hsl(236, 100%, 74%) !default;
$color-button-yellow-bg: hsl(36, 96%, 81%) !default;
$color-button-yellow-bg-hover: hsl(36, 95%, 61%) !default;
$color-button-green-bg-hover: hsl(133, 78%, 23%) !default;
