.o-form-field,
.o-form-actions {
  margin-top: 2rem;
}

.o-form-field p {
  font-size: 1rem;
}

.o-form-field__error-message {
  color: $color-danger;
  font-size: 1rem;
  display: none;
  margin-top: 2rem;
}

.o-form-field__date-selects {
  display: flex;
}

.o-form-field__date-selects select {
  margin-left: $global-spacing-unit / 2;
}

.o-form-field__date-selects select:first-child {
  margin-left: 0;
}

.o-form-field--inline {
  display: inline-block;
  margin-top: 1rem;
}

.o-form-field--radio,
.o-form-field--checkbox {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);

    & + .o-radio-label,
    & + .o-checkbox-label {
      display: block;
      position: relative;
    }

    & + .o-checkbox-label::before,
    & + .o-radio-label::before {
      content: '';
      position: relative;
      display: inline-block;
      top: 8px;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background: $color-white;
      border: 2px solid $color-medium-grey;
    }

    &:checked + .o-checkbox-label::before,
    &:checked + .o-radio-label::before {
      background: $color-brand-primary-blue;
    }

    &:focus + label::before {
      outline: 0;
      box-shadow: 0 0 0 3px $color-brand-yellow;
    }

    &:disabled + label {
      color: $color-medium-grey;
    }

    &:disabled + label::before,
    &:disabled + .o-checkbox-label::before,
    &:disabled + .o-radio-label::before {
      background: $color-light-grey;
      border: 2px solid $color-light-grey;
    }
  }

  input[type='radio'] + .o-radio-label::before {
    border-radius: 50%;
  }

  input[type='radio'] + .o-radio-label::after {
    border-radius: 50%;
  }

  input[type='radio']:checked + .o-radio-label::after {
    content: "";
    position: absolute;
    top: 18px;
    left: 10px;
    height: 10px;
    width: 10px;
    background-color: $color-white;
  }

  input[type='checkbox']:checked:not([disabled]) + .o-checkbox-label::after {
    content: "";
    position: absolute;
    top: 18px;
    left: 7px;
    border-left: 4px solid white;
    border-bottom: 4px solid white;
    height: 8px;
    width: 16px;
    transform: rotate(-45deg);
  }
}

.o-form-field--checkbox:not(.o-form-field--inline) + .o-form-field--checkbox:not(.o-form-field--inline),
.o-form-field--radio:not(.o-form-field--inline) + .o-form-field--radio:not(.o-form-field--inline) {
  margin-top: 1rem;
}

.o-form-field--error,
.field_with_errors {
  border-left: 4px solid $color-danger;
  padding-top: 0.75rem;
  padding-left: 1.5rem;
  padding-bottom: 0.75rem;

  .o-form-field__error-message {
    display: block;
  }
}

.o-hidden-radio {
  margin-left: -9999px;
}

@media (min-width: $global-breakpoint) {
  .o-form-field--inline {
    margin-top: 0;
  }
}
