.c-card-group {
  &:first-child {
    margin-top: -$global-spacing-unit;
  }
}

.c-card-group--grid,
.c-card-group--grid-1\/2 {
  > .c-card:only-child {
    flex: 1;
  }
}

@media (min-width: $global-breakpoint) {
  .c-card-group--grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .c-card {
      flex: 0 1 calc(33% - #{$global-spacing-unit});
    }

    &-1\/2 > .c-card {
      flex: 0 1 calc(50% - #{$global-spacing-unit});
    }
  }
}
