.c-contact-details {
  h4 {
    font-size: 1.25rem;
  }

  [data-open-chat] {
    @extend .c-button;

    text-align: center;
    width: 100%;
  }
}
