.u-is-hidden {
  display: none;
}

@media (max-width: $global-breakpoint) {
  .u-is-hidden--sm {
    display: none;
  }
}

@media (min-width: $global-breakpoint) {
  .u-is-hidden--med {
    display: none;
  }
}
