.c-goal__number,
.c-goal__number-info {
  color: $color-brand-primary-blue;
}

.c-goal__number,
.c-goal__objective {
  font-weight: bold;
}

.c-goal__number {
  font-size: 3.875rem;
  line-height: 1;
}
