.c-alert {
  @include box-content;

  border: 2px solid $color-white;
  border-radius: $global-border-radius * 2;

  h3 {
    margin-top: 0;
  }

  &--with-icon .c-alert__header {
    display: grid;
    grid-gap: $global-spacing-unit / 2;
    grid-template-columns: 1fr 7fr;
  }

  .c-alert__content > * + * {
    margin-top: 0;
  }

  .c-alert__icon {
    border: 2px solid $color-white;
    border-radius: 50%;
    margin-right: $global-spacing-unit;
  }

  .c-alert__icon + * {
    margin-top: $global-spacing-unit;
  }
}

.c-alert--notice {
  background-color: $color-bg-notice;
  border-color: darken($color-bg-notice, 20%);

  .c-alert__icon {
    background-color: darken($color-bg-notice, 20%);
  }
}

.c-alert--success {
  background-color: $color-bg-success;
  border-color: darken($color-bg-success, 20%);

  .c-alert__icon {
    background-color: darken($color-bg-success, 20%);
  }
}

.c-alert--danger {
  background-color: $color-bg-danger;
  border-color: darken($color-bg-danger, 20%);

  .c-alert__icon {
    background-color: darken($color-bg-danger, 20%);
  }
}

@media (min-width: $global-breakpoint) {
  .c-alert {
    .c-alert__content {
      display: flex;
      align-items: flex-start;
    }

    .c-alert__icon + * {
      margin-top: 0;
    }
  }
}
